import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../Assets/Images/prev-arrow.svg";
import nextArrow from "../Assets/Images/next-arrow.svg";

import testimonialImage from "../Assets/Images/testimonial.jpg";
import quotationImage from "../Assets/Images/quotation.svg";

import analyzeIcon from '../Assets/Images/insights.svg';
import alignmentIcon from '../Assets/Images/align.svg';
import advanceIcon from '../Assets/Images/advance.svg';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import "../App.scss";

const Results = (props) => {
  let navigate = useNavigate();
  const { id } = useParams();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isMember, setIsMember] = React.useState(false);
  const [name, setName] = React.useState("");
  const [score, setScore] = React.useState(0);
  const [subScore1, setSubScore1] = React.useState(0);
  const [subScore2, setSubScore2] = React.useState(0);
  const [subScore3, setSubScore3] = React.useState(0);
  const [subScore4, setSubScore4] = React.useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0]);

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    fetchResults();
  }, []);

  // to animate subScores on results page 
  useEffect(() => {
    const timer = setTimeout(() => {
      setSubScores([subScore1, subScore2, subScore3, subScore4]);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [subScore1, subScore2, subScore3, subScore4])

  const fetchResults = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setIsLoading(false);
          setName(data.name);
          setScore(data.score);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setIsMember(data.isTeamMember);
        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  const showTitleText = () => {
    if (score > 95) {
      return <h1>{name}, your church is extremely healthy</h1>
    } else if (score > 80) {
      return <h1>{name}, your church can become even healthier</h1>
    } else if (score > 70) {
      // return <h1>{name}, your church might need to work toward getting healthier</h1>
      return <h1>{name}, your church needs to work toward getting healthier</h1>
    } else {
      // return <h1>{name}, your church might be battling unhealthiness</h1>
      return <h1>{name}, your church is beset by unhealthiness</h1>
    }
  };

  const showScoreText = () => {
    if (score > 95) {
      return <div className="result-text">
        {/* <p>Congratulations! It looks like your church is displaying clear signs of moving toward great health.</p>
        <p>We estimate that less than 1 percent of churches will score this high, so we encourage your church to keep doing what it is presently doing to ensure it stays effective and healthy.</p> */}
        <p>Congratulations! It looks like your church is displaying clear signs of moving toward great health.</p>
        <p>We estimate that less than 1 percent of churches will score this highly, So, we encourage your church to continue what it is presently doing to ensure it stays effective and healthy.</p>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        {/* <p>While you are on a journey to ensuring your church becomes healthier, it looks like there's room for improvement!</p>
        <p>Your church has significant potential to move to the healthiest level, so we encourage your leadership to look for potential areas of growth and see what remedies are possible to ensure your church becomes even healthier.</p> */}
        <p>It's great that you are already working on key issues but your church can surely do more and get healthier.</p>
        <p>Your church has significant potential to move to the healthiest level. So, we encourage your leadership to look for potential areas of growth and see what remedies are possible to ensure your church becomes even healthier.</p>
      </div>
    } else if (score > 70) {
      return <div className="result-text">
        {/* <p>Based on your current score, it looks like your church needs to work on becoming healthier.</p>
        <p>Often, churches at this level might need significant work to move into the healthy category due to the multiple deficiencies they might need to remedy.</p>
        <p>We encourage your church to give special attention to the areas you scored lower in, so you can begin to grow toward greater church health.</p> */}
        <p>Based on your current score, it looks like your church needs to work on becoming healthier.</p>
        <p>Often, churches at this level might need significant work to move into the healthy category and I hope this assessment has revealed some clear starting points.</p>
        <p>We encourage your church to give special attention to the areas you scored lower in, so you can begin to grow toward greater church health.</p>
      </div>
    } else {
      return <div className="result-text">
        {/* <p>Based on your current score, it looks like your church is currently battling unhealthiness. The possibility of moving to health, outside of miraculous intervention, is remote.</p>
        <p>Though nothing is impossible with God, we rarely see churches in this category have any significant growth or meaningful ministries.</p>
        <p>By focusing and working on all the critical areas that need attention, you can begin the journey to improving your church's health!</p> */}
        <p>Based on your current score, it looks like your church is currently battling unhealthiness.</p>
        <p>While the task might seem hard and progress might appear minimal, please remember that significant growth or a meaningful ministry might be just around the corner.</p>
        <p>By focusing and working on all the critical areas revealed in this assessment, you can easily begin the journey to improving your church's health!</p>
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) {
      return null;
    } else {
      return <div className="invite-container">

        <h3>Invite your team to take this test</h3>
        <p>Compare your results with those of your team to gain new insights into your church's health and align around actionable goals.</p>

        <div className="invite-reasons">

          <div className="reason">
            <img src={analyzeIcon} alt="analyze" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover what level of health your church is currently at
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="align" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan for increased effectiveness as a team
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="advance" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Chart your course to move toward a greater level of church health
          </div>
        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>

      </div>
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking the test!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          Schedule a call with Carey to debrief the results of your test and
          ensure your church is prepared with next steps for future evangelism.
          <div className="cta-btn">
            <a
              href="https://calendly.com/kurianbk/hear-the-bleat-story"
              target="_blank"
            >
              <Button>TALK WITH CAREY</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            Invite your team to take the test and begin to align around an
            actionable strategy to grow toward a greater level of church health!
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>INVITE MY TEAM</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F7D54D" }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: score + '%' }}>
                  <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div>
                </div>
                <span className="msi_score" style={getLeftMargin()}>{score}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp" style={{ minWidth: '70%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '10%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '15%' }}></h4>
                <h4 className="vexpe" style={{ minWidth: '5%' }}></h4>
              </div>
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '70%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '10%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '15%' }}></h4>
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <div className="spacer"></div>

          {/* <div className="score-section">
              <div className="score-txt">{subScore2}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore2}
                />
              </div>
              LEADERS
            </div>

            <div className="score-section">
              <div className="score-txt">{subScore3}%</div>
              <div className="score-slider">
                <LinearProgress
                  className="custom-bar"
                  variant="determinate"
                  value={subScore3}
                />
              </div>
              STAFF
            </div> */}

          {showScoreText()}
          <div className="score-section-container">


            <div className="score-section">
              <p className="score-txt">{subScores[0]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[0]} /></div>
              <p className="score-name">PASTOR</p>
            </div>
            {/* </div>
      </div> */}

            <div className="score-section">
              <p className="score-txt">{subScores[1]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[1]} /></div>
              <p className="score-name">LEADERS</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[2]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[2]} /></div>
              <p className="score-name">STAFF</p>
            </div>

            <div className="score-section">
              <p className="score-txt">{subScores[3]}%</p>
              <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={subScores[3]} /></div>
              <p className="score-name">CONGREGATION</p>
            </div>
          </div>
        </div>
      </div>

      {showInvite()}

      {showTeamMember()}

      <div className="testimonal-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>This assessment gave us deeper insights into how healthy our church really was. It also helped us put a plan in place to ensure we remain effective and continue to grow toward greater church health together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div>

      {/* {showSchedule()} */}
      {showLegacyInvite()}

      {/* </div> */}

    </>

  );
};
export default Results;
