import React from 'react';
import { Fragment } from 'react';
import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';


const Footer = props => {

  const { windowWidth } = useWindowDimensions();

  return <Fragment>
    <footer>

      {windowWidth <= 830 &&
        <div className="bleat-logo-mobile">
          <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
        </div>}

      {/* <div className="bleat-logo-mobile">
        <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
      </div> */}
      
    </footer>
  </Fragment>
}

export default Footer;