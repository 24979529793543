import React from 'react';
import { Link } from "react-router-dom";

import Logo from '../../Assets/Images/logo.png';
import churchHealthLogo from '../../Assets/Images/logo-health.svg';
import churchHealthMobileLogo from '../../Assets/Images/mobile-logo.svg';
import bleatLogo from '../../Assets/Images/logo-bleat.svg';
import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const Header = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header>

        {windowWidth > 830 && <Link to="/start" className="logo"><img src={Logo} alt="Logo" /></Link>}
        <div className="assessment-title desktop" id="assessment-title"><img src={churchHealthLogo} alt="Church Health Logo" /></div>
        <div className="assessment-title mobile" id="assessment-title"><img src={churchHealthMobileLogo} alt="Church Health Logo" /></div>

        {windowWidth > 830 &&
          <div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>}

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

      </header>
    </>
  )
}

export default Header;
